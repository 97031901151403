export function getFileName(path) {
    if (typeof path === 'string') {
        const index = path.indexOf('_')
        if (index > -1) {
            return path.substring(index + 1)
        } else {
            return ''
        }
    } else {
        return ''
    }
}