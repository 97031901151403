<template>
  <div>
    <a-config-provider :locale="zh_CN">
      <router-view />
    </a-config-provider>
  </div>
</template>


<script>
import zh_CN from "ant-design-vue/es/locale/zh_CN";

export default {
  data() {
    return {
      zh_CN: Object.freeze(zh_CN)
    };
  },

  mounted() {
    if (process.env.NODE_ENV === "development") {
      document.title = "【开发环境】水务智能设计平台";
    }
  }
};
</script>