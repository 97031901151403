import axios from 'axios'
import { message, } from 'ant-design-vue'

if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = '/api'
}
const instance = axios.create({
})


instance.interceptors.response.use(function (response) {

    const { status, data = {} } = response;

    if (status === 200) {
        if (data.code !== 200) {
            message.error(data.message ? data.message : '异常错误！')
            return data;
        }
    } else if (status === 201) {
        message.success("操作成功！")
    }

    return data.data;

}, function (error) {
    const { data = {} } = error.response;
    message.error(data.message ? data.message : '异常错误！')

    if (data.code === 401) {
        if (window.location.pathname + window.location.hash !== '/#/login') {
            window.location = '/#/login'
            window.localStorage.clear();
        }
    }

    return Promise.reject(data)
})

function request({ url, method, headers, params, data, timeout = 1000 * 300 }) {

      return  instance({
            url, method, headers: {
                ...headers,
                token: window.localStorage.getItem('token')
            }, params, data, timeout
        })

}

export default request;