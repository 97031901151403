// import store from '@/store'
// 有 name 属性的都是缓存组件
// isPublic 意味着只要登录成功就能看到的页面
export default [
  {
    path: "/register",
    component: () => import("@/views/register"),
  },
  {
    path: "/login",
    component: () => import("@/views/login"),
  },
  {
    path: "/vip",
    component: () => import("@/views/vip"),
  },

  {
    path: "/",
    component: () => import("@/layout"),

    beforeEnter: (to, from, next) => {
      const token = window.localStorage.getItem("token");

      if (!token) {
        // 未登录直接路由到登录页

        if (from.path === "/login") {
          next(false);
          return;
        }
        next({
          path: "/login",
          query: {
            redirect: to.fullPath,
          },
        });
        return;

      }

      next();

      // if (to.meta.isPublic) {
      //   next();
      //   return;
      // }
      //   const menuList = window.localStorage.getItem("menuList");
      //   if (menuList) {
      //     const menus = JSON.parse(menuList);
      //     const res = menus.find((item) => item.path === to.path);
      //     if (res) {
      //       next();
      //     } else {
      //       next("/403");
      //     }
      //   } else {
      //     console.log("local storage don't has menu list");
      //     next("/403");
      //   }
    },

    children: [
      {
        path: "",
        redirect: "/project",
      },
      {
        path: "project",
        component: () => import("@/views/project"),
      },
      {
        path: "project/add",
        component: () => import("@/views/project/add"),
      },
      {
        path: "task",
        component: () => import("@/views/task"),
      },
      {
        path: "task/add",
        component: () => import("@/views/task/add"),
      },
      {
        path: "task/param",
        component: () => import("@/views/task/param"),
      },
      {
        path: "task/detail",
        component: () => import("@/views/task/detail"),
      },

      {
        path: "task/preview",
        component: () => import("@/views/task/preview"),
      },

      {
        path: "book",
        component: () => import("@/views/book"),
      },

      {
        path: "message",
        component: () => import("@/views/message"),
      },
      {
        path: "me",
        component: () => import("@/views/me"),
      },
      {
        path: "system",
        component: () => import("@/views/system"),
      },
      {
        path: "user",
        component: () => import("@/views/user"),
      },
      {
        path: "approve",
        component: () => import("@/views/approve"),
      },
      {
        path: "403",
        component: () => import("@/views/403"),
      },
      {
        path: "*",
        component: () => import("@/views/404"),
      },
    ],
  },
];
