import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import DataDictFinder from '@/components/data-dict-finder'

import './css/index.less'

import {
  Button, Menu, Layout, Icon, Card, Table, Tabs, Form,
  Row, Col, Alert, Input, Checkbox, Select, Modal, Descriptions,
  Space, Divider, Dropdown, Badge, Avatar, Tree, message,
  Result, Upload, Progress, Breadcrumb, Statistic, List,
  Steps, Radio, InputNumber, Tooltip, ConfigProvider, FormModel,
  Empty, Pagination, DatePicker, Tag, Timeline,
  Carousel, Transfer, Cascader, Skeleton, Slider, Spin,
  Switch, Popconfirm, Popover, AutoComplete
} from 'ant-design-vue'

// 常用方法
import encrypt from './utils/encrypt'
import { selectFilterOption, ratioFormatter, inputNumberFormat } from '@/utils/antdv'
import { getFileName } from '@/utils/file'

Vue.config.productionTip = false

Vue
  .use(Button).use(Menu).use(Layout).use(Icon).use(Card).use(Table)
  .use(Tabs).use(Form).use(Row).use(Col).use(Alert).use(Input).use(Checkbox)
  .use(Select).use(Modal).use(Descriptions).use(Space).use(Divider).use(Dropdown)
  .use(Badge).use(Avatar).use(Tree).use(Result).use(Upload).use(Progress)
  .use(Breadcrumb).use(Statistic).use(List).use(Steps).use(Radio)
  .use(InputNumber).use(Tooltip).use(ConfigProvider).use(FormModel)
  .use(Empty).use(Pagination).use(DatePicker).use(Tag).use(Timeline)
  .use(Carousel).use(Transfer).use(Cascader).use(Skeleton).use(Slider).use(Spin)
  .use(Switch).use(Popconfirm).use(Popover).use(AutoComplete)

Vue.prototype.$message = message;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$error = Modal.error;
Vue.prototype.$success = Modal.success;


Vue.component('DataDictFinder', DataDictFinder) // 数据字典查找器

Vue.prototype.$encrypt = encrypt;
Vue.prototype.$selectFilterOption = selectFilterOption;
Vue.prototype.$ratioFormatter = ratioFormatter;
Vue.prototype.$inputNumberFormat = inputNumberFormat;
Vue.prototype.$getFileName = getFileName;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
