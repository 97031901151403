// select 搜索框搜索时根据名称搜索
export function selectFilterOption(input, option) {
    return option.componentOptions.children[0].text.indexOf(input) >= 0;
}

export function ratioFormatter(value) {
    return value + "%";
}

export function inputNumberFormat(value) {
    const arr = value.toString().split('.')
    const num = arr[0];
    const res = num.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    if (arr.length > 1) {
        return res + '.' + arr[1]
    } else {
        return res;
    }
}