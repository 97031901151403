import Vue from 'vue'
import Vuex from 'vuex'
// import login from './modules/login'
import setting from './modules/setting'

// import companySelector from './modules/company-selector'



Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    setting
  }
  //   login,
  //   auth,
  //   setting,
  //   project,
  //   tabs,
  //   message,
  //   projectConfig, // 项目配置
  //   allocate, // 给阶段分配金额
  //   allocate2021,
  //   allocate2022,
  //   allocate2023,
  //   majorAllocate,
  //   adjustAllocate, // 调整阶段的产值分配
  //   adjustMajorAllocate, // 调整阶段的 专业 的产值分配

  //   projectDetail, // 项目详情页面
  //   socket,

  //   approval, // 审批
  //   approvalDetail, // 审批详情页面

  //   projectSelector, // 项目选择器
  //   companySelector, // 公司选择器
  //   contractSelector, // 合同选择器

  //   invoiceSelector, // 发票选择器

  //   department,

  //   organization, // 组织
  //   area, // 区域

  //   employeeSelector, // 员工选择器

  //   weather, // 天气
  //   document, // 文章

  //   interviewDetail, // 面试详情页面

  //   humanResources, // 人力资源-组织架构管理

  //   draft, // 草稿箱

  //   epc,

  //   holiday, // 国家法定节假日

  //   study, // 在线学习


  // }
})
